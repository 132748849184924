document.addEventListener('DOMContentLoaded', () => {
  const logoutButton = document.querySelector('[data-js="logout-button"]')

  if (logoutButton) {
    logoutButton.addEventListener('click', () => {
      fetch('https://fetlife.best/api/logout')
      .then(response => {
        if (response.status === 200) {
          window.open('https://fetlife.best/login.html', '_self')
        } else {
          const error = new Error('cant logout')
          error.response = response
          throw error
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
    })
  }

  const role = localStorage.getItem('role')

  if (role === 'admin') {
    adminView()
  }

  function adminView() {
    const sidebar = document.querySelector('[data-js="sidebar"]')

    const usersButton = document.createElement('li')
    usersButton.classList.add('nav-item')
    usersButton.innerHTML = `
    <a class="nav-link" href="user.html">
      <svg class="nav-icon">
        <use xlink:href="node_modules/@coreui/icons/sprites/free.svg#cil-user"></use>
      </svg>
      Users
    </a>`

    const settingsButton = document.createElement('li')
    settingsButton.classList.add('nav-item')
    settingsButton.innerHTML = `
    <a class="nav-link" href="settings.html">
      <svg class="nav-icon">
        <use xlink:href="node_modules/@coreui/icons/sprites/free.svg#cil-settings"></use>
      </svg>
      Settings
    </a>`

    sidebar.append(usersButton, settingsButton)
  }
})
